var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4" },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6", md: "4", lg: "3" } },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dense: "", color: "secondary", dark: "" } },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "text-subtitle-1" },
                            [_vm._v("Contacts")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list",
                        { attrs: { dense: "", shaped: "" } },
                        [
                          _c(
                            "v-list-item-group",
                            {
                              attrs: { color: "primary" },
                              model: {
                                value: _vm.item,
                                callback: function($$v) {
                                  _vm.item = $$v
                                },
                                expression: "item"
                              }
                            },
                            [
                              _vm._l(_vm.conversations, function(
                                conversation,
                                index
                              ) {
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      key: index,
                                      attrs: { link: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectConversation(
                                            conversation
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "primary--text" },
                                            [_vm._v("mdi-account-box-multiple")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            domProps: {
                                              textContent: _vm._s(
                                                conversation.contact.name
                                              )
                                            }
                                          }),
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              _vm._s(
                                                conversation.contact
                                                  .phone_formatted
                                              ) +
                                                " | " +
                                                _vm._s(
                                                  conversation.contact.type
                                                )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6", md: "8", lg: "9" } },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dense: "", color: "secondary", dark: "" } },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "text-subtitle-1" },
                            [
                              _vm._v(
                                " Messages for " +
                                  _vm._s(_vm.$_.get(_vm.contact, "name")) +
                                  " "
                              ),
                              _vm.conversation
                                ? _c("span", { staticClass: "caption" }, [
                                    _vm._v(
                                      "( id: " +
                                        _vm._s(
                                          _vm.$_.get(_vm.conversation, "id")
                                        ) +
                                        " )"
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-4",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.refresh($event)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-refresh")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary darken-2",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.dialog = true
                                }
                              }
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-message-text")
                              ]),
                              _vm._v("Send Reply ")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-timeline",
                        { attrs: { "align-top": "" } },
                        [
                          _vm._l(_vm.messages, function(message, index) {
                            return [
                              _c(
                                "v-timeline-item",
                                {
                                  key: index,
                                  attrs: {
                                    small: "",
                                    "fill-dot": "",
                                    left: message.status === "received",
                                    right: message.status != "received",
                                    color: _vm.itemColor(message.status)
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "opposite" },
                                      slot: "opposite"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(message.created_at) +
                                          " | " +
                                          _vm._s(message.status)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-card",
                                    {
                                      attrs: {
                                        outlined: "",
                                        color: _vm.messageColor(message.status)
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("psi-copy-button", {
                                            attrs: {
                                              dense: "",
                                              value: message.Body,
                                              label: "Message",
                                              btnClass: "mr-1"
                                            }
                                          }),
                                          _vm._v(
                                            " " + _vm._s(message.Body) + " "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          ref: "dialog",
          attrs: { "max-width": "600" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm.dialog
            ? _c("send-text-message", {
                attrs: {
                  title: _vm.title,
                  reply: "",
                  contact: _vm.contact,
                  search_id: _vm.search_id,
                  phoneNumber: _vm.contact.phone_formatted
                },
                on: {
                  close: function($event) {
                    _vm.dialog = false
                  },
                  sent: _vm.refresh
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }