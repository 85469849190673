<template>
    <div>
        <psi-page-title :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></psi-page-title>
        <!-- Content always starts with a v-card  
        -->
        <v-card class="mt-4">
            <v-card-text>
                <v-row fluid>
                    <v-col cols="6" md="4" lg="3">
                        <v-toolbar dense color="secondary" dark>
                            <v-toolbar-title class="text-subtitle-1">Contacts</v-toolbar-title>
                        </v-toolbar>
                        <v-list dense shaped>
                            <v-list-item-group v-model="item" color="primary">
                                <template v-for="(conversation,index) in conversations">
                                    <v-list-item
                                        :key="index"
                                        link
                                        @click="selectConversation(conversation)"
                                    >
                                        <v-list-item-avatar>
                                            <v-icon class="primary--text">mdi-account-box-multiple</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="conversation.contact.name"></v-list-item-title>
                                            <v-list-item-subtitle>{{conversation.contact.phone_formatted}} | {{conversation.contact.type}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                    <v-col cols="6" md="8" lg="9">
                        <v-toolbar dense color="secondary" dark>
                            <v-toolbar-title class="text-subtitle-1">
                                Messages for {{$_.get(contact,'name')}}
                                <span
                                    v-if="conversation"
                                    class="caption"
                                >( id: {{$_.get(conversation,'id')}} )</span>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click.stop="refresh" class="mr-4">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                            <v-btn class="secondary darken-2" @click.stop="dialog=true">
                                <v-icon class="mr-2">mdi-message-text</v-icon>Send Reply
                            </v-btn>
                        </v-toolbar>
                        <v-timeline align-top>
                            <template v-for="(message,index) in messages">
                                <v-timeline-item
                                    :key="index"
                                    small
                                    fill-dot
                                    :left="message.status === 'received'"
                                    :right="message.status != 'received'"
                                    :color="itemColor(message.status)"
                                >
                                    <span
                                        slot="opposite"
                                    >{{message.created_at}} | {{message.status}}</span>
                                    <v-card outlined :color="messageColor(message.status)">
                                        <v-card-text>
                                            <psi-copy-button
                                                dense
                                                :value="message.Body"
                                                label="Message"
                                                btnClass="mr-1"
                                            ></psi-copy-button>
                                            {{message.Body}}
                                        </v-card-text>
                                    </v-card>
                                </v-timeline-item>
                            </template>
                        </v-timeline>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialog" max-width="600" ref="dialog">
            <!-- Adding v-if will ensure the component is mounted each time we open the dialog and won't have to reset text area and validation -->
            <send-text-message
                v-if="dialog"
                :title="title"
                reply
                :contact="contact"
                :search_id="search_id"
                :phoneNumber="contact.phone_formatted"
                @close="dialog=false"
                @sent="refresh"
            ></send-text-message>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "user-conversations",
    components: {
        SendTextMessage: () =>
            import("@/components/messages/components/SendTextMessage"),
    },
    props: {
        id: {
            type: [String, Number],
            required: false,
            default: "",
        },
    },
    data() {
        return {
            item: null,
            dialog: false,
            conversation: null,
            messages: [],
            contact: {},
            search_id: "",
            page: {
                title: "Conversations",
                icon: "mdi-account-box-multiple",
            },
            breadcrumbs: [
                {
                    text: "Messages",
                    disabled: false,
                    to: "/messages/view",
                },
                {
                    text: "Conversations",
                    disabled: true,
                },
            ],
        };
    },
    watch: {
        conversations() {
            this.conversationsUpdated();

            this.messages = this.conversation ? this.conversation.messages : [];
            this.contact = this.conversation ? this.conversation.contact : {};
            this.search_id = this.conversation
                ? this.conversation.messages[0].search_id
                : {};
        },
    },
    computed: {
        ...mapGetters("Message", ["loading", "conversations"]),
        ...mapGetters("User", ["user"]),
        title() {
            return `Reply to ${this.contact.name} ${this.contact.phone_formatted}`;
        },
    },
    mounted() {
        this.getUserConversations(this.user.id);
    },
    methods: {
        ...mapActions("Message", ["getUserConversations"]),
        itemColor(status) {
            switch (status) {
                case "failed":
                    return "error";
                case "received":
                    return "alternate1 lighten-2";
                default:
                    return "alternate4 lighten-2";
            }
        },
        messageColor(status) {
            switch (status) {
                case "failed":
                    return "";
                case "received":
                    return "alternate1 lighten-3";
                default:
                    return "alternate4 lighten-3 ";
            }
        },
        selectConversation(conversation) {
            this.conversation = conversation;
            this.contact = conversation.contact;
            this.messages = conversation.messages;
        },
        conversationsUpdated() {
            if (this.conversations.length > 0) {
                if (this.id) {
                    this.conversation = this.conversations.find(
                        (val) => val.id === parseInt(this.id)
                    );
                    this.item = this.conversations.findIndex(
                        (val) => val.id === parseInt(this.id)
                    );
                    if (this.item === -1) {
                        // We didn't find a conversation for this id, set to initial conversation
                        this.item = 0;
                        this.conversation = this.conversations[0];
                    }
                } else {
                    this.item = 0;
                    this.conversation = this.conversations[0];
                }
            } else {
                this.conversation = null;
                this.item = null;
            }
        },
        refresh() {
            this.getUserConversations(this.user.id);
        },
    },
};
</script>

<style scoped>
</style>